<template>
  <div>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <Dialog
      :visible.sync="show"
      :style="{ width: $isMobile() ? '100vw' : '30vw' }"
      :header="'Alterar PAT'"
      :modal="true"
      :closable="false"
    >
      <form
        name="ChangePatForm"
        class="p-col-12"
        @submit.prevent="saveNewPat"
        style="min-height: 15vh"
      >
        <p>
          <b>Pat Atual: {{ pat }}</b>
        </p>
        <div class="p-fluid p-formgrid p-grid" style="min-height: 50vh">
          <div class="p-field p-col-12 p-md-12 p-mt-4">
            <span class="p-float-label">
              <Dropdown
                name="newPat"
                :options="openPats"
                :filter="true"
                :optionLabel="'name'"
                :optionValue="'id'"
                v-model="currentPat"
                v-validate="'required'"
                v-bind:class="[
                  { 'p-invalid': errors.has('newPat') },
                  'form-control',
                ]"
              />
              <label for="newPat">Selecionar Nova Pat</label>
            </span>
            <small
              v-if="errors.has('newPat')"
              class="p-error"
              role="alert"
            >
              A patente é obrigatória
            </small>
          </div>
        </div>
      </form>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="saveNewPat"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="cancel"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import ticketsService from "../../services/tickets.service";
import utilitiesService from "../../services/utilities.service";

export default {
  name: "ChangePat",
  props: ["showToast", "show", "pat", "selected"],
  data() {
    return {
      currentPat: this.pat,
      openPats: [],
    };
  },
  async created() {
    this.openPats = await utilitiesService.getPats();
  },
  watch: {
    show() {
      if (this.show === true) {
        this.currentPat = this.pat;
      }
    },
  },
  methods: {
    cancel() {
      return this.$emit("cancel");
    },
    async saveNewPat() {
      const isValid = await this.$validator.validateAll();
      if (!isValid) {
        this.loading = false;
        return;
      }

      this.$validator.pause();
      this.$validator.reset();
      for (let ticket of this.selected) {
        try {
          const response = await ticketsService.changePat(ticket.id, this.currentPat);

          if (!response) {
            if (this.showToast) {
              this.$toast.add({
                severity: "error",
                summary: "Erro ao alterar a patente",
                detail: `Erro ao alterar a patente para o ticket ${ticket.id}`,
                life: 3000,
              });
            }
            this.cancel();
            return;
          }

          if (this.showToast) {
            this.$toast.add({
              severity: "success",
              summary: `Patente alterada com sucesso para o ticket ${ticket.id}`,
              detail: "",
              life: 3000,
            });
          }
        } catch (error) {
          console.error("Erro ao alterar a patente:", error);
          if (this.showToast) {
            this.$toast.add({
              severity: "error",
              summary: "Erro inesperado",
              detail: `Ocorreu um erro ao tentar alterar a patente.`,
              life: 3000,
            });
          }
        }
      }

      this.$emit("changePatOk", {
        status: "ok",
        newPat: this.currentPat,
        selected: this.selected,
      });
    }
  }
};
</script>
