import axios from "axios";
import authHeader from "./auth-header";
import AuthService from "./auth.service";
import { config } from "../config";

class Tickets {
  getPriorityList() {
    return axios
      .get(`${config["apiUrl"]}helpdesk/tickets/priority`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }
  getCategoryList() {
    return axios
      .get(`${config["apiUrl"]}helpdesk/tickets/categories`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }
  getStatusList() {
    return axios
      .get(`${config["apiUrl"]}helpdesk/tickets/status`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }
  getByStatus(status) {
    return axios
      .get(`${config["apiUrl"]}helpdesk/tickets/status/${status}`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }
  getByStatusPaginator(status, queryStringParametersObj) {
    const params = new URLSearchParams(queryStringParametersObj);
    return axios
      .get(
        `${config["apiUrl"]}helpdesk/tickets/status/${status}/paginate?${params}`,
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }
  countByStatus(status) {
    return axios
      .get(`${config["apiUrl"]}helpdesk/tickets/status/${status}/count`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  setAssigned(ticketId, $bodyParms) {
    return axios
      .patch(
        `${config["apiUrl"]}helpdesk/tickets/${ticketId}/assigned`,
        $bodyParms,
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.status != undefined && response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  setCategory(ticketId, $bodyParms) {
    return axios
      .patch(
        `${config["apiUrl"]}helpdesk/tickets/${ticketId}/category`,
        $bodyParms,
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.status != undefined && response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  setPriority(ticketId, $bodyParms) {
    return axios
      .patch(
        `${config["apiUrl"]}helpdesk/tickets/${ticketId}/priority`,
        $bodyParms,
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.status != undefined && response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }
  setStatus(ticketId, $bodyParms) {
    return axios
      .patch(
        `${config["apiUrl"]}helpdesk/tickets/${ticketId}/status`,
        $bodyParms,
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.status != undefined && response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }
  getTicketInfo(ticketId) {
    return axios
      .get(`${config["apiUrl"]}helpdesk/tickets/${ticketId}`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  sendMessage(ticketId, formData) {
    let headers = authHeader();
    headers["Content-Type"] = "multipart/form-data";
    return axios
      .post(
        `${config["apiUrl"]}helpdesk/tickets/${ticketId}/message`,
        formData,
        {
          headers: headers,
        }
      )
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }
  setContract(ticketId, $bodyParms) {
    return axios
      .patch(
        `${config["apiUrl"]}helpdesk/tickets/${ticketId}/contract`,
        $bodyParms,
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.status != undefined && response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  getBasicInformation(ticketId) {
    return axios
      .get(`${config["apiUrl"]}helpdesk/tickets/${ticketId}/basicInformation`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  mergeTicket(ticketId, $bodyParms) {
    return axios
      .post(
        `${config["apiUrl"]}helpdesk/tickets/${ticketId}/merge`,
        $bodyParms,
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.status != undefined && response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  search(term) {
    return axios
      .get(`${config["apiUrl"]}helpdesk/tickets/search/${term}`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  searchPaginator(term, queryStringParametersObj) {
    const params = new URLSearchParams(queryStringParametersObj);
    return axios
      .get(
        `${config["apiUrl"]}helpdesk/tickets/search/paginate/${term}?${params}`,
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  importTickets(ticketId, $bodyParms) {
    return axios
      .post(
        `${config["apiUrl"]}helpdesk/tickets/${ticketId}/import`,
        $bodyParms,
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.status != undefined && response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  addNote(ticketId, $bodyParms) {
    return axios
      .post(
        `${config["apiUrl"]}helpdesk/tickets/${ticketId}/note`,
        $bodyParms,
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.status != undefined && response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  removeNote(ticketId, noteId) {
    return axios
      .delete(
        `${config["apiUrl"]}helpdesk/tickets/${ticketId}/note/${noteId}`,
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.status != undefined && response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  changePat(ticketId, pat) {
    return axios
      .patch(
        `${config["apiUrl"]}helpdesk/tickets/${ticketId}/pat/${pat}`,
        {
          id: ticketId,
          pat: pat,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.status != undefined && response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  getTicketHistoryEquipment(equipmentID) {
    return axios
      .get(
        `${config["apiUrl"]}helpdesk/tickets/${equipmentID}/history/equipment`,
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.error("ERROR:: ", error.response ? error.response.data : error);
        return null;
      });
  }

  getTicketHistoryContract(ticketId) {
    return axios
      .get(`${config["apiUrl"]}helpdesk/tickets/${ticketId}/history/contract`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.error("ERROR:: ", error.response ? error.response.data : error);
        return null;
      });
  }

  getTicketHistoryEntity(ticketId) {
    return axios
      .get(`${config["apiUrl"]}helpdesk/tickets/${ticketId}/history/entity`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.error("ERROR:: ", error.response ? error.response.data : error);
        return null;
      });
  }

  createTicket(params) {
    return axios
      .post(`${config["apiUrl"]}helpdesk/tickets`, params, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }
}
export default new Tickets();
